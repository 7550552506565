import axios from 'axios'

const API = 'entregas'

const ENTREGAS = {
    informe(params){
        return axios(`${API}/informe`,{ params })
    },
    informe_dias(params){
        return axios(`${API}/informe-dias`,{ params })
    },
}

export default ENTREGAS
