<template>
    <section class="w-100 custom-scroll overflow-auto" style="height:calc(100vh - 130px);">
        <div class="row mx-0 mt-3 mb-3 justify-content-start">
            <div class="col-auto d-middle text-general f-17 f-500">
                Rango de análisis
            </div>
            <div class="col-2">
                <el-select v-model="rango" placeholder="Seleccionar" size="small" class="w-100">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-3 px-0 mx-2 date-picker-edit">
                <el-date-picker
                v-model="fecha"
                class="w-100"
                size="small"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                :disabled="disable_fecha"
                popper-class="date-picker-edit-popper"
                type="daterange"
                unlink-panels
                start-placeholder="Fecha inicio"
                end-placeholder="Fecha fin"
                />
            </div>
            <div class="col-auto">
                <div class="btn-general bg-general f-14 text-white br-8 px-3" @click="informes">
                    Mostrar informe
                </div>
            </div>
            <cargando v-if="cargandoMetricas" mini />
        </div>
        <!-- Tabla -->
        <div class="row mx-0 mt-3 py-3 bg-white br-t-8">
            <div class="col-6 mx-0 text-general f-600 f-18">
                Entregas a los {{ $config.vendedor }}
            </div>
            <div class="col-6 ml-auto mx-0">
                <p class="mb-0 text-general text-right f-18">
                    {{ agregarSeparadoresNumero(total_leecheros_paginado) }}/{{ agregarSeparadoresNumero(total_leecheros) }} {{ $config.vendedor }}
                </p>
            </div>
        </div>
        <div class="row mx-3 align-items-center justify-center py-3">
            <div class="col-3 text-center">
                <div class="d-middle-center text-general cr-pointer justify-center">
                    {{ $config.vendedor }}
                </div>
            </div>
            <div class="col text-center">
                <div class="d-middle-center text-general mx-3 cr-pointer justify-center" @click="ordenar(1)">
                    <div class="position-relative mx-1">
                        <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                        <div class="position-relative" :class="containerClass(1)">
                            <i class="f-18 position-absolute" :class="iconFiltro(1)" style="" />
                        </div>
                    </div>
                    Pedidos
                </div>
            </div>
            <div class="col text-center">
                <div class="d-middle-center text-general cr-pointer justify-center" @click="ordenar(2)">
                    <div class="position-relative mx-1">
                        <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                        <div class="position-relative" :class="containerClass(2)">
                            <i class="f-18 position-absolute" :class="iconFiltro(2)" style="" />
                        </div>
                    </div>
                    Ventas
                </div>
            </div>
            <div class="col text-center">
                <div class="d-middle-center text-general mx-3 cr-pointer justify-center" @click="ordenar(3)">
                    <div class="position-relative mx-1">
                        <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                        <div class="position-relative" :class="containerClass(3)">
                            <i class="f-18 position-absolute" :class="iconFiltro(3)" style="" />
                        </div>
                    </div>
                    Promedio pedido
                </div>
            </div>
            <div class="col text-center">
                <div class="d-middle-center text-general mx-3 cr-pointer justify-center" @click="ordenar(4)">
                    <div class="position-relative mx-1">
                        <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                        <div class="position-relative" :class="containerClass(4)">
                            <i class="f-18 position-absolute" :class="iconFiltro(4)" style="" />
                        </div>
                    </div>
                    Entregas
                </div>
            </div>
            <div class="col text-center">
                <div class="d-middle-center text-general cr-pointer justify-center" @click="ordenar(5)">
                    <div class="position-relative mx-1">
                        <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                        <div class="position-relative" :class="containerClass(5)">
                            <i class="f-18 position-absolute" :class="iconFiltro(5)" style="" />
                        </div>
                    </div>
                    Promedio entrega
                </div>
            </div>
            <div class="col text-center">
                <div class="d-middle-center text-general mx-3 cr-pointer justify-center" @click="ordenar(6)">
                    <div class="position-relative mx-1">
                        <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                        <div class="position-relative" :class="containerClass(6)">
                            <i class="f-18 position-absolute" :class="iconFiltro(6)" style="" />
                        </div>
                    </div>
                    Comisión
                </div>
            </div>
        </div>
        <div v-for="(leechero,c) in leecheros_paginado" :key="c" class="row mx-0 border-t-gris p-1 bg-white align-items-center cr-pointer" style="min-height:69px;" @click="AbrirInfoClientes(leechero)">
            <div class="col-3 px-1">
                <div class="d-flex justify-left">
                    <img :src="leechero.avatar" class="border" style="width:54px;height:54px;border-radius:50%;object-fit:cover;" />
                    <div class="mx-3 py-2">
                        <p class="mb-0 f-18 f-500 text-general">
                            {{ leechero.nombre }}
                        </p>
                        <p class="mb-0 text-general2 f-14">
                            Cedis {{ leechero.nombre_cedis }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col px-1 text-center">
                <p class="mb-0 f-22 f-500 text-general">
                    {{ agregarSeparadoresNumero(leechero.pedidos) }}
                </p>
            </div>
            <div class="col px-1 text-center">
                <p class="mb-0 f-22 f-500 text-general">
                    {{ separadorNumero(leechero.ventas) }}
                </p>
            </div>
            <div class="col px-1 text-center">
                <p class="mb-0 f-22 f-500 text-general">
                    {{ separadorNumero(leechero.promedio_pedido) }}
                </p>
            </div>
            <div class="col px-1 text-center">
                <p class="mb-0 f-22 f-500 text-general">
                    {{ agregarSeparadoresNumero(leechero.entregas) }}
                </p>
            </div>
            <div class="col px-1 text-center">
                <p class="mb-0 f-22 f-500 text-general">
                    {{ separadorNumero(leechero.promedio_entrega) }}
                </p>
            </div>
            <div class="col px-1 text-center">
                <p class="mb-0 f-22 f-500 text-general">
                    {{ separadorNumero(leechero.comision) }}
                </p>
            </div>
        </div>
        <!-- Tabla -->
        <div class="row mx-0 justify-content-end">
            <div class="col-auto mr-5" style="width:170px;" />
            <div class="col-3 d-middle-center">
                <div v-if="total_leecheros > total_leecheros_paginado" class="cr-pointer br-20 text-white p-1 px-3 bg-general3 mx-1" @click="agregar_mas_clientes">
                    Ver más
                </div>
                <div v-if="total_leecheros > total_leecheros_paginado" class="cr-pointer br-20 text-white p-1 px-3 bg-general3 mx-1" @click="verTodos">
                    Ver todos
                </div>
            </div>
            <div class="col-3 d-middle-center" />
            <div class="col-auto ml-3">
                <p class="mb-0 text-general f-18">
                    {{ agregarSeparadoresNumero(total_leecheros_paginado) }}/{{ agregarSeparadoresNumero(total_leecheros) }} {{ $config.vendedor }}
                </p>
            </div>
        </div>
        <!-- Partials -->
        <modal-entregas-dias ref="EntregasDias" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Entregas from "../../services/entregas/tabla";

export default {
    components:{
        modalEntregasDias: () => import('./partials/modalEntregasDias')
    },
    data(){
        return{
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            leecheros: [],
            leecheros_paginado: [],
            total_leecheros: 0,
            total_leecheros_paginado: 0,
            total_por_pagina: 10,
            cargandoMetricas: true,
            clicksPedidos: 1,
            order: true,
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            id_cedis:'cedis/id_cedis',
        })
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;
            default:
                break;
            }
        },
        id_cedis(val,oldVal){
            if(oldVal == null) return
            if(this.primeraVez) return
            this.cargando()
            this.montar()
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            if(this.primeraVez) return
            this.cargando()
            this.montar()
        },
        cargandoMetricas(val){
            this.$store.commit('cedis/setCargandoTerminado', !val)

        }
    },
    mounted(){
        this.$store.commit('cedis/setCargandoTerminado', false)
        this.montar()
    },
    methods: {
        cargando(){
            this.cargandoMetricas = true
        },
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.informe()
                    }, 1000);
                }else{
                    await this.informe()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        iconFiltro(dato){
            if (this.clicksPedidos === dato){
                return this.order ? 'flecha-orden text-general abajo mover-abajo' : 'flecha-orden girar text-general arriba mover-arriba'
            } else {
                return 'text-gris'
            }
        },
        containerClass(dato){
            if (this.clicksPedidos === dato){
                return this.order ? ' text-general abajo' : 'girar text-general arriba'
            } else {
                return 'text-gris'
            }
        },
        ordenar(tipo){
            this.clicksPedidos = tipo
            let mismoTipo = tipo == this.clicksPedidos
            if (mismoTipo){
                this.order = !this.order
            }else{
                this.order = orden == 'desc'
            }
            let ordenar = this.order ? 'desc' : 'asc'
            switch (tipo){
            case 1:
                this.leecheros_paginado = _.orderBy(this.leecheros,'pedidos',ordenar).slice(0,this.total_por_pagina)
                break;
            case 2:
                this.leecheros_paginado = _.orderBy(this.leecheros,'ventas',ordenar).slice(0,this.total_por_pagina)
                break;
            case 3:
                this.leecheros_paginado = _.orderBy(this.leecheros,'promedio_pedido',ordenar).slice(0,this.total_por_pagina)
                break;
            case 4:
                this.leecheros_paginado = _.orderBy(this.leecheros,'entregas',ordenar).slice(0,this.total_por_pagina)
                break;
            case 5:
                this.leecheros_paginado = _.orderBy(this.leecheros,'promedio_entrega',ordenar).slice(0,this.total_por_pagina)
                break;
            case 6:
                this.leecheros_paginado = _.orderBy(this.leecheros,'comision',ordenar).slice(0,this.total_por_pagina)
                break;

            default:
                break;
            }
        },
        agregar_mas_clientes(){
            if (this.total_leecheros >= this.total_por_pagina){
                this.total_por_pagina += 20
                this.leecheros_paginado = this.leecheros.slice(0,this.total_por_pagina)
                this.total_leecheros_paginado = this.leecheros_paginado.length
            }
        },
        verTodos(){
            if (this.total_leecheros >= this.total_por_pagina){
                this.total_por_pagina = this.total_leecheros
                this.leecheros_paginado = this.leecheros
                this.total_leecheros_paginado = this.leecheros_paginado.length
            }
        },
        AbrirInfoClientes(cliente){
            console.log(cliente);
            let params = {
                id: cliente.created_by,
                avatar: cliente.avatar,
                nombre: cliente.nombre,
                cedis: cliente.nombre_cedis,
            }
            this.$refs.EntregasDias.toggle(params);
        },
        async informe(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Entregas.informe(params)
                this.$validar(data)
                this.total_por_pagina = 10
                this.leecheros = data.data.leecheros
                this.leecheros_paginado = this.leecheros.slice(0,this.total_por_pagina)
                this.total_leecheros = data.data.total_leecheros
                this.total_leecheros_paginado = this.leecheros_paginado.length

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoMetricas = false
            }
        },
        async informes(){
            try {
                this.cargando()

                await this.informe()

            } catch (error){
                console.log(error)
            }
        },
        EntregasDias(){
            this.$refs.EntregasDias.toggle();
        }
    }
}
</script>
<style lang="scss">
.br-12{
    border-radius: 12px;
}
</style>
